/** Auth user token key */
export const AUTH_USER_TOKEN_KEY = 'ReactAmplify.TokenKey';

export const DASHBOARD_ROUTE = '/dashboard';
export const SALES_ROUTE = '/order/production-order';
export const PROFILE_ROUTE = '/profile';
export const PRODUCT_ROUTE = '/product';
export const PRODUCT_ADD_ROUTE = '/product/add';
export const PRODUCT_EDIT_ROUTE = '/product/edit/:id';
export const CATEGORY_ROUTE = '/category';
export const CATEGORY_ADD_ROUTE = '/category/add';
export const CATEGORY_EDIT_ROUTE = '/category/edit/:id';
export const SHOWROOM_ORDER_ROUTE = '/order/showroom-order';
export const PRODUCTION_ORDER_ROUTE = '/order/production-order';
export const FACTORY_ORDER_ROUTE = '/order/factory-order';
export const CUSTOM_ORDER_ROUTE = '/order/custom-order';
export const SHOWROOM_INVOICE_ROUTE = '/order/showroom/invoice/';
export const PRODUCTION_INVOICE_ROUTE = '/order/production/invoice';
export const FACTORY_SALES_INVOICE_ROUTE = '/order/factory-sales/invoice';
export const SHOWROOM_ORDER_EDIT_ROUTE = '/order/showroom/update/';
export const PAYMENT_ROUTE = '/payment';
export const SignUp_ROUTE = '/signup';

//regex for tusand seperator
export const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
