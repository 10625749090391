import * as React from 'react';

function SvgLogo(props) {
    return (
        <svg
            id="Layer_2"
            data-name="Layer 2"
            width="166"
            height="27"
            viewBox="0 0 626.3 133.68"
            {...props}
        >
            <defs>
                <style>
                    {`
            .cls-1 {
              fill: #0f1131;
            }
    
            .cls-1, .cls-2, .cls-3, .cls-4 {
              stroke-width: 0px;
            }
    
            .cls-2 {
              fill: #febd12;
            }
    
            .cls-3 {
              fill: #ed196f;
            }
    
            .cls-4 {
              fill: #7155a4;
            }
          `}
                </style>
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <g>
                        <path
                            class="cls-4"
                            d="M133.68,0c0,26.06-14.75,49.67-38.65,66.84C70.8,49.43,37.17,38.64,0,38.64V0h133.68Z"
                        />
                        <path
                            class="cls-2"
                            d="M95.03,66.84c-24.23,17.41-57.86,28.2-95.03,28.2v-56.39c37.17,0,70.8,10.79,95.03,28.2Z"
                        />
                        <path
                            class="cls-3"
                            d="M133.68,133.68H0s0-38.64,0-38.64c37.17,0,70.8-10.79,95.03-28.2,23.9,17.17,38.65,40.78,38.65,66.84Z"
                        />
                    </g>
                    <g>
                        <path
                            class="cls-1"
                            d="M255.09,59.45c-3.21-6.39-7.54-11.18-12.98-14.36-5.44-3.18-11.48-4.77-18.12-4.77-5.44,0-10.12.76-14.04,2.29-3.92,1.52-7.16,3.31-9.71,5.35-1.67,1.33-3.07,2.53-4.25,3.61l.58-13.69V0h-12.89v127.31c.76.54,2.15,1.17,4.16,1.88,2.01.71,4.43,1.4,7.26,2.08,2.83.68,5.89,1.25,9.18,1.71,3.29.46,6.6.69,9.92.69,9.09,0,17.06-2.11,23.91-6.32,6.86-4.22,12.2-10.09,16.04-17.63,3.84-7.53,5.75-16.25,5.75-26.16s-1.61-17.72-4.82-24.12ZM241.95,106.87c-2.88,5.69-6.65,9.94-11.3,12.77-4.65,2.83-9.64,4.24-14.98,4.24-4.24,0-8.09-.37-11.55-1.1-3.46-.73-5.97-1.67-7.55-2.82v-60.04c2.28-2.02,5.53-4.04,9.75-6.06,4.33-2.07,9.02-3.1,14.08-3.1,8.6,0,15.1,2.95,19.51,8.85,4.41,5.9,6.56,14.49,6.45,25.75-.06,8.65-1.52,15.82-4.41,21.5Z"
                        />
                        <path
                            class="cls-1"
                            d="M331.43,51.33c-2.58-4.19-6.2-7.1-10.85-8.73-4.65-1.63-10.08-2.45-16.28-2.45-4.9,0-9.63.61-14.2,1.84-4.57,1.22-8.5,2.57-11.79,4.04-3.29,1.47-5.46,2.56-6.49,3.26l3.27,9.47c1.58-1.14,3.81-2.31,6.69-3.51,2.88-1.2,6.02-2.19,9.43-2.98,3.4-.79,6.62-1.18,9.67-1.18,7.13,0,12.45,1.17,15.95,3.51,3.51,2.34,5.26,6.99,5.26,13.96v9.47c-3.16,0-6.81.16-10.98.49-4.16.33-8.39.88-12.69,1.67-4.3.79-8.21,1.86-11.75,3.22-6.37,2.34-11.22,5.59-14.57,9.75-3.35,4.16-5.02,9.24-5.02,15.22,0,5.28,1.18,9.81,3.55,13.59,2.37,3.78,5.63,6.68,9.79,8.69,4.16,2.01,8.91,3.02,14.24,3.02,6.53,0,12.28-1.09,17.26-3.26,4.87-2.13,8.53-4.32,10.98-6.55v9.81h12.4v-64.63c0-7.62-1.29-13.52-3.88-17.71ZM309.4,122.17c-3.35,1.04-6.95,1.55-10.81,1.55-6.53,0-11.24-1.54-14.12-4.61-2.88-3.07-4.33-6.98-4.33-11.71,0-4.08,1.17-7.45,3.51-10.12,2.34-2.66,5.79-4.84,10.36-6.53,2.56-1.03,5.62-1.86,9.18-2.49,3.56-.62,7.06-1.07,10.49-1.35,3.43-.27,6.23-.41,8.41-.41v29.36c-.71.7-2.1,1.63-4.24,2.83-2.29,1.28-5.1,2.44-8.45,3.47Z"
                        />
                        <polygon
                            class="cls-1"
                            points="432.15 40.15 416.32 40.15 369.12 87.15 369.42 77.2 369.42 0 356.69 0 356.69 89.12 356.69 133.68 369.42 133.68 369.42 115.89 369.14 101.68 383.82 87.34 416.76 133.68 432.1 133.68 392.78 78.6 432.15 40.15"
                        />
                        <path
                            class="cls-1"
                            d="M501.92,60.35c-1.6-4.22-3.84-7.83-6.69-10.85-2.86-3.02-6.26-5.33-10.2-6.94-3.95-1.6-8.34-2.35-13.18-2.24-8.71.05-16.08,2.11-22.12,6.16-6.04,4.05-10.64,9.6-13.79,16.65-3.16,7.05-4.73,15.11-4.73,24.2,0,14.53,3.4,25.88,10.2,34.07,6.8,8.19,16.57,12.28,29.3,12.28,3.75,0,7.63-.35,11.63-1.06,4-.71,7.69-1.69,11.06-2.94,3.37-1.25,6.04-2.69,8-4.33l-2.77-8.65c-1.58,1.03-3.77,2.08-6.57,3.14-2.8,1.06-5.79,1.95-8.98,2.65-3.18.71-6.16,1.06-8.94,1.06-5.6,0-10.61-1.05-15.02-3.14-4.41-2.09-7.92-5.48-10.53-10.16-2.61-4.68-4.05-10.91-4.33-18.69-.03-1.12-.04-2.2-.04-3.27h59.61c.33-1.9.52-3.98.57-6.24.05-2.26.08-4.8.08-7.63-.11-5.17-.97-9.86-2.57-14.08ZM491.93,79.33h-47.23c.49-4.54,1.36-8.53,2.63-11.92,2.31-6.15,5.54-10.62,9.67-13.43,4.13-2.8,8.81-4.2,14.04-4.2,5.49,0,9.82,1.31,12.98,3.92,3.15,2.61,5.35,6.15,6.57,10.61,1.22,4.46,1.67,9.47,1.35,15.02Z"
                        />
                        <polygon
                            class="cls-1"
                            points="586.7 115.71 581.67 123.61 626.3 123.61 626.3 133.68 568.4 133.68 568.4 124.12 607.65 58.8 612.52 50.73 568.4 50.73 568.4 40.15 626.3 40.15 626.3 49.57 586.7 115.71"
                        />
                        <path
                            class="cls-1"
                            d="M562.98,40.15v10.73c-7.72.03-12.61,1.05-21.68,5.3-4.6,2.17-9.23,5.26-12.18,7.57-.01,23.31-.01,46.62,0,69.92h-13.58V40.15h11.59l.76,13.47c.18-.15.37-.3.58-.46,3.04-2.35,7.64-5.41,12.25-7.57,9.29-4.36,14.35-5.41,22.26-5.44Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgLogo;
