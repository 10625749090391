import './CustomOrder.scss';
import { Table, Button, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RiDeleteBinLine, RiCheckFill } from 'react-icons/ri';
import React, { useState, useEffect, useContext } from 'react';
import MainMenu from 'Component/Menu/mainMenu';
import i18n from 'i18n';
import { ButtonXXXS } from 'Component/Button';
import ConfirmCustomOrderComponent from 'Component/Orders/ConfirmCustomOrderComponent';
import Function from 'Component/Service/Function';
import { OrderContext } from 'Context/OrderContext';
import NoOrdersComponent from 'Component/Orders/NoOrdersComponent';
import moment from 'moment';
import { useGetTenant } from 'Store/userDetails';
import customNotification from 'Util/Notification';
const { confirm } = Modal;

const CustomOrderComponent = () => {
    const tenant = useGetTenant();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const { customOrderData, setCustomOrderData } = useContext(OrderContext);
    console.log('customOrderData', customOrderData);

    useEffect(() => {
        fetchProductList();
    }, []);
    useEffect(() => {
        fetchProductList();
    }, [refresh]);

    const fetchProductList = () => {
        setLoading(true);
        try {
            let extraurl = `custom_orders?tenant=${tenant}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    // let list = [];
                    console.log('get Custom Order list', responseJson._embedded.collection);
                    let list = responseJson._embedded.collection
                        .map((v, i) => {
                            return {
                                ...v,
                                key: i + 1,
                                time: moment(v.createdAt).format('DD-MM-YY h:mm:ss A'),
                            };
                        })
                        .filter(el => el.accepted === false);
                    console.log('get product list', list);
                    setData(list);
                    setCustomOrderData(list);
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    const onCancelCustomOrder = data => {
        data.accepted = true;
        data.quantity = 0;
        data.amount = 0;
        let param = data;
        console.log(param);
        try {
            let extraurl = `custom_orders/${data.orderId}?tenant=${tenant}`;

            Function.postFetch(extraurl, param)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get product list on Cancel', responseJson);
                    customNotification('Successful', 'Order updated successfully', 'success');
                    setRefresh(!refresh);
                    if (
                        responseJson.statusCode === 409 ||
                        responseJson.statusCode === 404 ||
                        responseJson.statusCode === 400 ||
                        responseJson.statusCode === 500
                    ) {
                        alert(responseJson.message);
                        customNotification('Error', 'Something is not working!', 'error');
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    const columns = [
        {
            title: 'Time',
            dataIndex: 'time',
            width: '10%',
        },
        {
            title: 'Showroom Name',
            dataIndex: 'userName',
            width: '15%',
        },
        {
            title: 'Product Name',
            dataIndex: 'title',
            width: '20%',
        },
        {
            title: 'Description',
            dataIndex: 'details',
            width: '48%',
        },
        {
            title: '',
            key: 'action',
            width: '10%',
            render: (text, record) => (
                <div>
                    <ConfirmCustomOrderComponent
                        rowData={record}
                        onComplete={e => {
                            setRefresh(!refresh);
                        }}
                    />
                </div>
            ),
        },
        {
            title: '',
            key: 'action',
            width: '10%',
            render: (text, record) => (
                <div>
                    <ButtonXXXS
                        onClick={e => {
                            confirm({
                                title: 'Do you want to delete these items?',
                                icon: <ExclamationCircleOutlined />,
                                content: 'When clicked the OK button, this cannot be reverted',
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                    console.log('api will be called here');
                                    onCancelCustomOrder(record);
                                },
                                // onCancel() {
                                // },
                            });
                        }}
                        style={{
                            backgroundColor: '#FF0A0A',
                        }}
                        leftIcon={<RiDeleteBinLine />}
                        text={i18n.i18n('remove_btn')}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className="custom-order">
            <MainMenu>
                <Spin spinning={loading}>
                    <br />
                    <br />
                    {customOrderData.length > 0 ? (
                        <Table
                            className="custom-order-table"
                            columns={columns}
                            dataSource={customOrderData}
                            bordered
                            pagination={false}
                        />
                    ) : (
                        <NoOrdersComponent />
                    )}
                </Spin>
            </MainMenu>
        </div>
    );
};

export default CustomOrderComponent;
