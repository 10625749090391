import './FactoryOrder.scss';
import React, { useState, useEffect } from 'react';
import { Table, Tabs, Row, Col, Input, Select, Button, Modal, Tooltip, Spin, Empty } from 'antd';
import MainMenu from 'Component/Menu/mainMenu';
import { ButtonXS } from 'Component/Button';
import moment from 'moment';
import i18n from 'i18n';
import { useHistory } from 'react-router-dom';
import SvgRefresh from 'Component/Icon/Refresh';
import DatePicker from 'Component/Date Picker/dateRangePicker';
import {
    FACTORY_SALES_INVOICE_ROUTE,
    numberWithCommas,
    SHOWROOM_INVOICE_ROUTE,
} from 'Util/constants';
import Function from 'Component/Service/Function';
import NoOrdersComponent from 'Component/Orders/NoOrdersComponent';
import { useGetTenant } from 'Store/userDetails';
import { groupBy } from 'lodash';

const FactoryOrderComponent = () => {
    const tenant = useGetTenant();
    let history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setCol] = useState([]);
    const [catList, setCatList] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').utc(true).toISOString());
    const [endDate, setEndDate] = useState(moment().endOf('day').utc(true).toISOString());

    useEffect(() => {
        fetchProductList(startDate, endDate);
    }, []);

    const fetchProductList = (startDate, endDate) => {
        setLoading(true);
        try {
            let extraurl = `factory_orders?tenant=${tenant}&startDate=${startDate}&endDate=${endDate}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get order list', responseJson);
                    if (responseJson.total === 0) {
                        setLoading(false);
                        return;
                    }
                    console.log('get Factory list', responseJson?._embedded?.collection);
                    // console.log(
                    //     'record',
                    //     responseJson?._embedded?.collection.filter(
                    //         v => v.userId === 'iqULoeTV5QP7D6fCJW4zGB2kD3I3',
                    //     ),
                    // );
                    const uniqueUserIds = [
                        ...new Set(responseJson?._embedded?.collection?.map(v => v.userId)),
                    ];
                    const filteredList = uniqueUserIds.map(userId => {
                        return responseJson?._embedded?.collection?.filter(
                            v => v.userId === userId,
                        );
                    });

                    console.log('filtered list', filteredList);
                    let list = responseJson?._embedded?.collection.map((v, i) => {
                        return {
                            ...v,
                            key: i + 1,
                            Bill: v.amount,
                            'Showroom Name': v.userName,
                        };
                    });
                    const pricrByUserID = groupBy(list, 'userName');
                    console.log('get pricrByUserID', pricrByUserID);
                    setData(list);

                    let cols = list[0];
                    let name = Object.keys(cols).filter(v => v == 'Showroom Name');
                    let others = Object.keys(cols).filter(v => v == 'Bill');

                    let all_col = name.concat(responseJson.categoryList, others);

                    const col = all_col.map(v => {
                        return {
                            title: v,
                            dataIndex: v,
                        };
                    });
                    console.log(col);
                    setCol(col);
                    setCatList([...responseJson.categoryList, 'Bill']);
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    const onDateChange = e => {
        console.log(e);
        let startDate = moment(e.startDate).startOf('day').utc(true).toISOString();
        let endDate = moment(e.endDate).endOf('day').utc(true).toISOString();
        console.log(startDate, endDate);
        setStartDate(startDate);
        setEndDate(endDate);
        fetchProductList(startDate, endDate);
    };

    const onRefresh = () => {
        let startDate = moment().startOf('day').utc(true).toISOString();
        let endDate = '';
        fetchProductList(startDate, endDate);
    };

    return (
        <MainMenu>
            <Spin spinning={loading}>
                <div
                    className="factory-order"
                    style={{
                        padding: '50px 20px 0 20px',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <DatePicker getDate={onDateChange} />
                            {/* <div style={{ marginLeft: '100px', fontStyle: 'normal', fontWeight: 'normal', fontSize: '19px', lineHeight: '28px', color: '#000000' }}>
								<p>Time Remaining: {'02:15:30'}</p>
							</div> */}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ButtonXS
                                onClick={e =>
                                    history.push(
                                        `${FACTORY_SALES_INVOICE_ROUTE}?startDate=${startDate}&endDate=${endDate}`,
                                    )
                                }
                                text={i18n.i18n('print_btn')}
                                style={{
                                    backgroundColor: '#4890FC',
                                    width: '161px',
                                    fontSize: '19px',
                                    borderRadius: '5px',
                                    boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                                }}
                            />
                            <ButtonXS
                                onClick={onRefresh}
                                leftIcon={<SvgRefresh />}
                                text={i18n.i18n('refresh_btn')}
                                style={{
                                    marginLeft: '50px',
                                    backgroundColor: '#FD9A26',
                                    width: '161px',
                                    fontSize: '19px',
                                    borderRadius: '5px',
                                    boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <div>
                        {data.length > 0 ? (
                            <Table
                                className="showroom-order-table"
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                summary={pageData => {
                                    console.log(pageData);
                                    let summaryRowArr = [];

                                    for (const cat of catList) {
                                        let totalAmount = 0;
                                        pageData.forEach(row => {
                                            totalAmount += row[cat];
                                        });

                                        summaryRowArr.push(
                                            <Table.Summary.Cell>{totalAmount}</Table.Summary.Cell>,
                                        );
                                    }

                                    console.log('sumary', summaryRowArr);
                                    // const row = {<Table.Summary.Row>
                                    // 	<Table.Summary.Cell>Total</Table.Summary.Cell>
                                    // 	for (const catCell of summaryRowArr) {

                                    // 	}
                                    // </Table.Summary.Row>}

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                                            </Table.Summary.Row>

                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                                {summaryRowArr}
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            />
                        ) : (
                            <NoOrdersComponent />
                        )}
                    </div>
                </div>
            </Spin>
        </MainMenu>
    );
};

export default FactoryOrderComponent;
