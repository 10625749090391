import * as React from 'react';

function SvgCustomIcon(props) {
    return (
        <svg
            enable-background="new 0 0 48 48"
            height="48px"
            id="Layer_4"
            version="1.1"
            viewBox="0 0 48 48"
            width="48px"
            {...props}
        >
            <g>
                <path
                    d="M20.215,30.449l1.549-1.55l-2.811-2.812l-1.549,1.552c-0.902-0.598-1.908-1.043-2.983-1.322V24h-4.99v2.317   c-1.099,0.284-2.128,0.742-3.045,1.359l-1.487-1.487l-2.811,2.81L3.6,30.511c-0.579,0.886-1.012,1.872-1.284,2.923H0v4.985h2.315   c0.279,1.076,0.725,2.085,1.322,2.987l-1.55,1.55l2.811,2.809l1.55-1.549c0.904,0.599,1.914,1.046,2.993,1.324v2.312h4.97V45.54   c1.054-0.271,2.042-0.708,2.93-1.286l1.612,1.611l2.811-2.81l-1.587-1.589c0.62-0.922,1.08-1.958,1.363-3.062h2.312V33.45H21.54   C21.263,32.369,20.816,31.355,20.215,30.449z M11.926,40.015c-2.258,0-4.088-1.83-4.088-4.088s1.831-4.089,4.088-4.089   s4.088,1.831,4.088,4.089S14.184,40.015,11.926,40.015z"
                    fill="#241F20"
                />
                <path
                    d="M29.754,33.127c-1.375,0-2.623-0.445-3.514-0.934l-0.635,2.479c0.805,0.465,2.18,0.846,3.598,0.91v2.012   h2.076v-2.16c2.436-0.422,3.768-2.033,3.768-3.918c0-1.904-1.016-3.068-3.535-3.957c-1.799-0.68-2.539-1.122-2.539-1.821   c0-0.593,0.443-1.186,1.818-1.186c1.525,0,2.5,0.487,3.049,0.72l0.615-2.392c-0.699-0.339-1.652-0.635-3.07-0.699v-1.863h-2.074   v2.011c-2.266,0.445-3.578,1.906-3.578,3.768c0,2.054,1.545,3.112,3.811,3.876c1.566,0.529,2.244,1.037,2.244,1.842   C31.787,32.66,30.961,33.127,29.754,33.127z"
                    fill="#241F20"
                />
                <path
                    d="M46.965,24.411l0.017-0.007c0,0-0.107-0.336-0.356-0.919c-0.018-0.04-0.035-0.079-0.053-0.118   c-0.163-0.375-0.382-0.837-0.665-1.382c-0.027-0.05-0.054-0.099-0.081-0.148c-1.585-2.988-5.071-8.111-11.988-12.81L40,0H21.2   l5.565,9.099c-2.425,1.77-8.546,6.594-11.665,12.235h1.524h0h2.837l0,0.001C23.255,16.471,30,10.375,30,10.375   s13.667,9.875,14.167,19.542c0.041,2.125-0.292,10.834-11.584,14.042c-1.833,0.291-4.583,0.499-7.5-0.396v3.62   C26.741,47.702,28.496,48,30.326,48C40.087,48,48,40.087,48,30.326C48,28.248,47.623,26.263,46.965,24.411z"
                    fill="#241F20"
                />
            </g>
        </svg>
    );
}

export default SvgCustomIcon;
