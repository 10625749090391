import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, Spin, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './profileDashboardStyle.scss';
import { ButtonM } from 'Component/Button';
import i18n from 'i18n';
import { MainMenu } from 'Component';
import { useGetTenant } from 'Store/userDetails';
import Function from 'Component/Service/Function';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import * as Constants from 'Util/constants';
import { ColorSettings, H1, H2 } from 'Typography';
const { confirm } = Modal;

const ProfileDashboard = () => {
    const tenant = useGetTenant();
    const [data, setData] = useState([]);
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getFirebaseUserData();
    }, []);

    const getFirebaseUserData = async () => {
        setLoading(true);
        const userRef = collection(db, 'users');

        const q = query(userRef, where('tenant', '==', tenant));

        const querySnapshot = await getDocs(q);

        let list = querySnapshot.docs.map((doc, i) => {
            return {
                ...doc.data(),
                key: i + 1,
            };
        });
        setData(list);
        console.log('Data');
        setLoading(false);
    };

    const deleteUserData = async id => {
        setLoading(true);
        await deleteDoc(doc(db, 'users', id));
        getFirebaseUserData();
        console.log('User Deleted!');
        setLoading(false);
    };

    const columns = [
        {
            title: 'SL',
            dataIndex: 'key',
            colSpan: 1,
        },
        {
            title: 'Name',
            dataIndex: 'displayName',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'User Type',
            dataIndex: 'userType',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        // {
        //     title: '',
        //     key: 'action',
        //     // responsive: ['md'],
        //     render: (text, record) => (
        //         <div>
        //             {record.isAdmin ? null : (
        //                 <Button
        //                     onClick={e => {
        //                         console.log('Button Pressed', e);
        //                         // history.push(INVOICE_ROUTE);
        //                     }}
        //                     style={{
        //                         backgroundColor: '#4890FC',
        //                         borderRadius: '5px',
        //                         color: 'white',
        //                         fontSize: '13px',
        //                         width: '71px',
        //                         lineHeight: '19px',
        //                         boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
        //                     }}
        //                 >
        //                     {i18n.i18n('edit_btn')}
        //                 </Button>
        //             )}
        //         </div>
        //     ),
        // },
        {
            title: 'Action',
            key: 'action',
            responsive: ['md'],
            render: (text, record) => (
                <div>
                    {record.isAdmin ? null : (
                        <Button
                            leftIcon={<DeleteOutlined />}
                            onClick={e =>
                                confirm({
                                    title: (
                                        <>
                                            আপনি কি চান <b>{record.displayName}</b> কে ডিলিট করতে?
                                        </>
                                    ),
                                    icon: <ExclamationCircleOutlined />,
                                    content:
                                        'ডিলিট করুন বাটনে ক্লিক করলে, এটি রিটার্ন করা যাবে না!!!!',
                                    okText: 'ডিলিট করুন',
                                    okType: 'danger',
                                    cancelText: 'না ',
                                    onOk: async () => {
                                        console.log('api will be called here');
                                        deleteUserData(record.uid);
                                    },
                                    // onCancel() {
                                    // },
                                })
                            }
                            style={{
                                backgroundColor: ColorSettings.colorRed,
                                borderRadius: '5px',
                                color: 'white',
                                fontSize: '13px',
                                width: '80px',
                                lineHeight: '19px',
                                boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            {i18n.i18n('Delete')}
                        </Button>
                    )}
                </div>
            ),
        },
    ];

    return (
        <MainMenu>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <H1
                    textTitle={i18n.i18n('User_Heading')}
                    textStyle={{
                        color: ColorSettings.colorBlack,
                        fontWeight: 'bold',
                        lineHeight: '5rem',
                    }}
                />

                <ButtonM
                    leftIcon={<PlusOutlined />}
                    text={i18n.i18n('add_new_user')}
                    style={{ margin: '20px 0 0px 20px', fontSize: '19px' }}
                    onClick={() => history.push(Constants.SignUp_ROUTE)}
                />
            </div>
            <Spin spinning={loading}>
                <div>
                    <Table
                        className="showroom-order-table"
                        columns={columns}
                        dataSource={data}
                        size="middle"
                        pagination={{
                            defaultPageSize: 100,
                        }}
                        // locale={{ emptyText: <NoOrdersComponent /> }}
                    />
                </div>
            </Spin>
        </MainMenu>
    );
};

export default ProfileDashboard;
