import { atom, useAtom } from 'jotai';
import { auth } from '../firebase';

const isAuthenticated = localStorage.getItem('isAuthenticated');

const currentUser = auth.currentUser;
var isAuthenticatedAtom = false;
if (currentUser != null && isAuthenticated === true) {
    console.log('User is logged in');

    isAuthenticatedAtom = atom(true);
} else {
    console.log('User is not logged in');
    isAuthenticatedAtom = atom(false);
}

export const useIsAuthenticated = () => useAtom(isAuthenticatedAtom);
