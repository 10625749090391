import './loginPage.scss';
import { useState, useMemo } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Form, Input, Space, Spin } from 'antd';
import { ColorSettings, H2, H4, H5 } from 'Typography';
import { ButtonM } from 'Component/Button';
import { Logo } from 'Component/Icon';
import imageData from 'assets/images/login/images';
import { useIsAuthenticated } from 'Store/authToken';
import Function from 'Component/Service/Function';
import * as Constants from 'Util/constants';
import { useSetTenant } from 'Store/userDetails';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';

const LoginPage = props => {
    const setTenant = useSetTenant();
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [stayLoggedIn, setStayLoggedIn] = useState(false);
    const [isUserAuthenticated, setIsUserAuthenticated] = useIsAuthenticated();
    console.log('authToken2', isUserAuthenticated);
    const handleEmailChange = e => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = e => {
        setPassword(e.target.value);
    };

    const handleStayLoggedInChange = e => {
        setStayLoggedIn(e.target.checked);
    };

    const requestLogin = () => {
        try {
            setLoading(true);
            let extraurl = 'login';
            let data = {
                email: email,
                password: password,
            };

            signInWithEmailAndPassword(auth, email, password)
                .then(userCredential => {
                    // Signed in
                    const user = userCredential.user;
                    console.log('user', user);
                    // ...
                })
                .catch(error => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log('error', errorCode, errorMessage);
                    // ..
                });
            console.log('Here!');

            Function.postFetch(extraurl, data)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get Login Details', responseJson);

                    setLoading(false);
                    if (
                        responseJson.statusCode === 409 ||
                        responseJson.statusCode === 404 ||
                        responseJson.statusCode === 400 ||
                        responseJson.statusCode === 500 ||
                        responseJson.statusCode === 401
                    ) {
                        // alert(responseJson.message);
                        setHasError(true);
                    } else {
                        setIsUserAuthenticated(true);
                        setTenant(responseJson.tenant);
                        localStorage.setItem('tenant', responseJson.tenant);
                        localStorage.setItem('isAuthenticated', true);
                        props.history.push(Constants.PRODUCT_ROUTE);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            setLoading(false);
            console.log('Error in Catch', error);
            alert(error);
        }
    };
    const handleLogin = () => {
        // Perform login logic here
        requestLogin();
    };

    const randomImageSrc = useMemo(() => {
        const randomIndex = Math.floor(Math.random() * imageData.length);
        return imageData[randomIndex].src;
    }, []);

    return (
        <div className="login-page">
            <div className="login-logo">
                <Logo width={'12em'} height={'4em'} alt="BakerZ Logo" />
            </div>
            <div className="login-form">
                <Form name="normal_login" className="login-form" onSubmit={handleLogin}>
                    <Spin spinning={loading}>
                        <H2
                            textTitle={'Login'}
                            textStyle={{ fontWeight: 'bold', marginBottom: 20 }}
                        />
                        <br />
                        <H4 textTitle="Welcome to BakerZ, Please log in to continue" />
                        <br />
                        {hasError && (
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <Alert
                                    message="ভুল তথ্য দিয়েছেন!"
                                    type="error"
                                    showIcon
                                    closable
                                    onClose={() => setHasError(false)}
                                />
                                <br />
                            </Space>
                        )}

                        <Form.Item
                            name="username"
                            rules={[
                                { required: true, message: 'Please input your email address!' },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Email Address"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="stayLoggedIn" valuePropName={stayLoggedIn} noStyle>
                                <Checkbox onChange={handleStayLoggedInChange}>
                                    <H5 textTitle="Stay Logged In" />
                                </Checkbox>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <ButtonM
                                text="Login"
                                style={{
                                    backgroundColor: ColorSettings.colorTheme,
                                    marginLeft: 10,
                                }}
                                htmlType={'submit'}
                                onClick={handleLogin}
                            />
                        </Form.Item>
                    </Spin>
                </Form>
            </div>
            <div className="login-image" style={{ backgroundImage: `url(${randomImageSrc})` }} />
        </div>
    );
};

export default LoginPage;
