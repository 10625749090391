import './ShowroomOrderStyle.scss';
import React, { useState, useEffect } from 'react';
import { Table, Select, Button, Spin } from 'antd';
import MainMenu from 'Component/Menu/mainMenu';
import { ButtonXS } from 'Component/Button';
import moment from 'moment';
import i18n from 'i18n';
import { useHistory } from 'react-router-dom';
import SvgRefresh from 'Component/Icon/Refresh';
import DatePicker from 'Component/Date Picker/dateRangePicker';
import NoOrdersComponent from 'Component/Orders/NoOrdersComponent';
import { SHOWROOM_INVOICE_ROUTE, SHOWROOM_ORDER_EDIT_ROUTE } from 'Util/constants';

import Function from 'Component/Service/Function';
import { useGetTenant } from 'Store/userDetails';
import { useSetorderAtom } from 'Store/orderStore';
import { useShowroomOrder, useSetShowroomOrder } from 'Store/showroomOrderStore';
import { ColorSettings } from 'Typography';
const { Option } = Select;

const ShowroomOrderComponent = () => {
    const tenant = useGetTenant();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const setOrderAtom = useSetorderAtom();
    const data = useShowroomOrder();
    const setShowroomOrderList = useSetShowroomOrder();

    const columns = [
        {
            title: 'SL',
            dataIndex: 'key',
            colSpan: 1,
        },
        {
            title: 'Showroom',
            dataIndex: 'userName',
        },
        {
            title: 'TIME',
            dataIndex: 'time',
            sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
            sortDirections: ['descend'],
            responsive: ['md'],
        },
        {
            title: 'ORDER VALUE',
            dataIndex: 'totalAmount',
        },
        {
            title: '',
            key: 'action',
            // responsive: ['md'],
            render: (text, record) => (
                <div>
                    <Button
                        onClick={e => {
                            console.log(
                                'record',
                                data.filter(v => v.userId === record.userId),
                            );
                            setOrderAtom(data.filter(v => v.userId === record.userId));
                            history.push(SHOWROOM_INVOICE_ROUTE + record.id);
                            // history.push(INVOICE_ROUTE);
                        }}
                        style={{
                            backgroundColor: '#4890FC',
                            borderRadius: '5px',
                            color: 'white',
                            fontSize: '13px',
                            width: '71px',
                            lineHeight: '19px',
                            boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        {i18n.i18n('print_btn')}
                    </Button>
                </div>
            ),
        },
        {
            title: '',
            key: 'action',
            responsive: ['md'],
            render: (text, record) => (
                <div>
                    <Button
                        onClick={e => {
                            history.push(SHOWROOM_ORDER_EDIT_ROUTE + record.id);
                        }}
                        style={{
                            backgroundColor: '#F8786F',
                            borderRadius: '5px',
                            color: 'white',
                            fontSize: '13px',
                            width: '71px',
                            lineHeight: '19px',
                            boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        {i18n.i18n('edit_btn')}
                    </Button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        let startDate = moment().startOf('day').utc(true).toISOString();
        let endDate = '';

        if (data.length === 0) {
            fetchProductList(startDate, endDate);
        }
    }, []);

    const fetchProductList = (startDate, endDate) => {
        setLoading(true);
        try {
            let extraurl = `orders?tenant=${tenant}&startDate=${startDate}&endDate=${endDate}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('Fetch Order List', responseJson);
                    let list = responseJson._embedded.collection.map((v, i) => {
                        return {
                            ...v,
                            key: i + 1,
                            time: moment(v.createdAt).format('DD-MM-YY h:mm:ss A'),
                        };
                    });
                    // setData(list);
                    setShowroomOrderList(list);
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    const onDateChange = e => {
        console.log(e);
        if (e == 'today') {
            let startDate = moment().startOf('day').utc(true).toISOString();
            let endDate = '';
            console.log(startDate, endDate);
            fetchProductList(startDate, endDate);
        } else if (e == 'yesterday') {
            let startDate = moment().subtract(1, 'days').startOf('day').utc(true).toISOString();
            let endDate = moment().subtract(1, 'days').endOf('day').utc(true).toISOString();
            console.log(startDate, endDate);
            fetchProductList(startDate, endDate);
        } else if (e == 'thismonth') {
            let startDate = moment().startOf('month').utc(true).toISOString();
            let endDate = '';
            console.log(startDate, endDate);
            fetchProductList(startDate, endDate);
        } else {
            let startDate = moment(e.startDate).startOf('day').utc(true).toISOString();
            let endDate = moment(e.endDate).endOf('day').utc(true).toISOString();
            console.log(startDate, endDate);
            fetchProductList(startDate, endDate);
        }
    };

    const onRefresh = () => {
        let startDate = moment().startOf('day').utc(true).toISOString();
        let endDate = '';
        fetchProductList(startDate, endDate);
    };

    return (
        <MainMenu>
            <Spin spinning={loading}>
                <div
                    className="showroom-order"
                    style={{
                        padding: '50px 20px 0 20px',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Select
                            placeholder="Select Date"
                            defaultValue="today"
                            style={{ width: 115 }}
                            onChange={onDateChange}
                        >
                            <Option value="today">Today</Option>
                            <Option value="yesterday">Yesterday</Option>
                            <Option value="thismonth">This Month</Option>
                        </Select>
                        {/* <div style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '19px', lineHeight: '28px', color: '#000000' }}>
						<p>Time Remaining: {'02:15:30'}</p>
					</div> */}
                        <DatePicker getDate={onDateChange} />
                        {/* <RemainingOrderComponent remaining_list={remaining_list} remaining_col={remaining_col} /> */}
                        <ButtonXS
                            onClick={onRefresh}
                            leftIcon={<SvgRefresh />}
                            text={i18n.i18n('refresh_btn')}
                            style={{
                                backgroundColor: ColorSettings,
                                width: '161px',
                                fontSize: '19px',
                                borderRadius: '5px',
                                boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                            }}
                        />
                    </div>
                    <br />
                    <br />
                    <div>
                        <Table
                            className="showroom-order-table"
                            columns={columns}
                            dataSource={data}
                            size="middle"
                            pagination={{
                                defaultPageSize: 100,
                            }}
                            locale={{ emptyText: <NoOrdersComponent /> }}
                        />
                    </div>
                </div>
            </Spin>
        </MainMenu>
    );
};

export default ShowroomOrderComponent;
