var ColorSettings = {
    colorPrimary: '#FD9A26',
    colorTheme: '#0F1131',
    colorWhite: '#FFFFFF',
    colorBlack: '#000000',
    colorGreen: '#27AE60',
    colorGreenDark: '#0F9D58',
    colorGreenLight: '#E9F7EF',
    colorRed: '#cc3300',
};

export { ColorSettings };
