import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyAOtFvJ9YbGaQACAB0ZC4Avh3kPi9SfLGg',
    authDomain: 'bakers-apphousebd.firebaseapp.com',
    databaseURL: 'https://bakers-apphousebd-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'bakers-apphousebd',
    storageBucket: 'bakers-apphousebd.appspot.com',
    messagingSenderId: '8382336119',
    appId: '1:8382336119:web:6fdc78189688cc3bb10a72',
    measurementId: 'G-9HH3B5ZMH9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);

export { db, auth };
